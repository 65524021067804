







































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  UserDetail,
  RoleInInstitution,
  ZuzhijiagouList,
  SearchUsers,
  AddComposeUser,
  GetSizhen,
} from "@/request/manage";
import { GetCenter } from "@/request/common";
import SelectSizhenshebei from "@/components/select-sizhenshebei.vue";
@Component({
  components: {
    SelectSizhenshebei,
  },
})
export default class Name extends Vue {
  private type: any = "新增";
  private data: any = {};
  private institution: any = {};
  private institutions: any[] = [];
  private user: any = {
    ars: [
      {
        institution_id: "",
        role_id: "",
        roles: [],
        parent_id: "",
      },
    ],
    关联设备ID: [],
  };
  private account: any = {};
  private accounts: any = {};
  private ifShowAccountSearch: boolean = false;
  private searchAccountText: any = "";
  private roles: any[] = [];
  private centers: any[] = [];
  private ifShowAddsizhen: boolean = false;
  private sizhens: any = []; // 四诊设备左边列表
  private search1: any = ""; // 添加四诊设备弹框左边搜索值
  private search2: any = ""; // 添加四诊设备弹框右边搜索值
  private searchResult: any = []; // 添加四诊设备弹框右边搜索结果
  private hasSearch: any = false; // 添加四诊设备弹框右边是否搜索
  private get configuration() {
    return this.$store.state.configuration;
  }
  private cancel() {
    this.ifShowAddsizhen = false;
  }
  private isRepeat(arr: any) {
    const hash = {};
    for (const i in arr) {
      if (Object.prototype.hasOwnProperty.call(arr, i)) {
        if ((hash as any)[arr[i]]) {
          return true;
        }
        (hash as any)[arr[i]] = true;
      }
    }
    return false;
  }
  private updataShebeiData(e: any) {
    let arr: any = [];
    e.forEach((ele: any) => {
      const index = arr.findIndex((val: any) => {
        return ele.hospital_id === val.hospital_id;
      });
      if (index == -1) {
        arr.push({
          hospital_id: ele.hospital_id,
          医院名称: ele["医院名称"],
        });
      }
    });
    this.user["关联设备ID"] = arr;
    this.user.doctor_data = e;
    this.cancel();
  }
  private open(e: any, i: any) {
    if (!e) {
      let arr: any = [];
      this.user.ars.forEach((ele: any) => {
        arr.push(ele.parent_id);
      });
      if (this.isRepeat(arr)) {
        this.$message.error("当前专病库已经创建角色，不能重复创建！");
        this.user.ars[i]["institution_id"] = "";
        // this.$set(this.user.ars[i], "institution_id", "");
        this.$forceUpdate();
        this.$set(this.user.ars[i], "institution_id", "");
      }
    }
  }
  private change(e: any, i: any) {
    const d: any = (this.$refs as any)[
      "myCascader" + i
    ][0].getCheckedNodes()[0];
    if (d.level == "1") {
      this.$set(this.user.ars[i], "parent_id", d.value);
    } else {
      if (d.parent.level == "1") {
        this.$set(this.user.ars[i], "parent_id", d.parent.value);
      } else {
        if (d.parent.parent.level == "1") {
          this.$set(this.user.ars[i], "parent_id", d.parent.parent.value);
        }
      }
    }
    this.getRoleInInstitution(e, i);
  }
  /**
   * @description 添加角色
   */
  private addOne() {
    if (this.user.ars.length < this.centers.length) {
      const obj = {
        institution_id: "",
        role_id: "",
        roles: [],
        parent_id: "",
      };
      this.user.ars.push(obj);
      this.$forceUpdate();
    }
  }
  /**
   * @description 删除角色
   */
  private delOne(i: any) {
    this.user.ars.splice(i, 1);
    this.$forceUpdate();
  }
  /**
   * @description 获得专病数据库列表
   */
  private getCenterList() {
    GetCenter(this).then((data: any) => {
      this.centers = data;
    });
  }
  /**
   * @description 获取用户详情
   */
  private getUserDetail() {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          user_id: this.user.user_id,
        },
      };
      UserDetail(this, params)
        .then((data: any) => {
          this.user = data;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 获得可选的组织结构列表
   */
  private getZuzhijiagouList(text: any) {
    const params: any = {
      params: {
        search: text,
        kind: true,
      },
    };
    ZuzhijiagouList(this, params).then((data: any) => {
      this.institutions = data;
    });
  }
  /**
   * @description 获得机构和角色的关系
   */
  private getRoleInInstitution(id: any, i: any) {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(false);
        return;
      }
      const params: any = {
        params: {
          institution_id: id,
        },
      };
      RoleInInstitution(this, params)
        .then((data: any) => {
          this.$set(this.user.ars[i], "roles", data);
          this.$forceUpdate();
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 角色改变
   */
  private roleChange() {
    // 清空可选账户的值
    this.user["关联账号"] = [];
    this.$forceUpdate();
  }
  /**
   * @description 打开搜索账号弹窗
   */
  private openSearchAccount() {
    this.ifShowAccountSearch = true;
    this.$nextTick().then(() => {
      (this.$refs["searchAccountSelect"] as any).focus();
      this.$nextTick().then(() => {
        this.searchAccountText = this.user["用户名"];
      });
    });
  }
  /**
   * @description 获得账号
   */
  private getAccount(text: any) {
    const params: any = {
      params: {
        search: text,
      },
    };
    SearchUsers(this, params).then((data: any) => {
      this.accounts = data;
    });
  }
  /**
   * @description 选择一个已有账号
   */
  private selectAccount(e: any) {
    this.user = e;
    this.ifShowAccountSearch = false;
  }
  /**
   * @description 保存
   */
  private save() {
    let institutionIds: any = [];
    let roleIds: any = [];
    this.user.ars.forEach((ele: any) => {
      institutionIds.push(ele.institution_id);
      roleIds.push(ele.role_id);
    });
    for (const i of institutionIds) {
      if (!i) {
        this.$message.warning("所属层级必须选择");
        return;
      }
    }
    for (const i of roleIds) {
      if (!i) {
        this.$message.warning("角色必须选择");
        return;
      }
    }

    if (this.user["姓名"] === "admin") {
      this.$message.warning("对不起，不能编辑超级管理员账号");
      return;
    }
    if (this.user["用户名"].length > 18) {
      this.$message.warning("账户名太长，请输入18个字符以内的账户名");
      return;
    }
    if (!this.user["用户名"]) {
      this.$message.warning("请填写账户名");
      return;
    }
    if (!this.user["姓名"]) {
      this.$message.warning("请填写姓名");
      return;
    }
    // if (!this.user["性别"]) {
    //   this.$message.warning("请选择性别");
    //   return;
    // }
    // if (!this.user["手机号"]) {
    //   this.$message.warning("请填写手机号");
    //   return;
    // }
    if (this.user["手机号"] && this.user["手机号"].length !== 11) {
      this.$message.warning("手机号长度不正确");
      return;
    }
    if (
      this.user["手机号"] &&
      !/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.user["手机号"])
    ) {
      this.$message.warning("手机号格式不正确");
      return;
    }
    if (!this.user["状态"]) {
      this.$message.warning("状态必须选择");
      return;
    }
    const params = JSON.parse(JSON.stringify(this.user));
    params.institution_id = institutionIds;
    params.role_id = roleIds;
    delete params.ars;
    AddComposeUser(this, params).then((data: any) => {
      this.$message.success(this.type + "成功");
      this.$router.back();
    });
  }
  private changeData() {
    if (!this.data || !this.data.user_id) {
      return;
    }
    this.user.user_id = this.data.user_id;
    this.getUserDetail().then(() => {
      this.user.ars = [];
      this.data["角色"].forEach((ele: any, i: any) => {
        const obj = {
          institution_id: "",
          role_id: "",
          roles: [],
          parent_id: "",
          disabled: true,
        };
        this.user.ars.push(obj);
        this.getRoleInInstitution(
          ele["组织机构"][ele["组织机构"].length - 1].institution_id,
          i
        ).then(() => {
          this.$set(
            this.user.ars[i],
            "institution_id",
            ele["组织机构"][ele["组织机构"].length - 1].institution_id
          );
          this.$set(
            this.user.ars[i],
            "parent_id",
            ele["组织机构"][0].institution_id
          );
          this.$set(this.user.ars[i], "role_id", ele.role.role_id);
          this.$forceUpdate();
        });
      });
    });
  }

  private addSizhen() {
    this.search1 = "";
    this.search2 = "";
    this.hasSearch = false;
    this.getSizhenList();
  }
  private delOneSizhen(i: any) {
    this.user["doctor_data"].splice(i, 1);
    this.$forceUpdate();
  }
  private getSizhenList() {
    const params = {
      params: {
        search: this.search1,
      },
    };
    GetSizhen(this, params).then((res: any) => {
      this.sizhens = res;
      this.ifShowAddsizhen = true;
    });
  }
  /**
   * @description 搜索右侧结果
   */
  private searchRight() {
    this.hasSearch = true;
    if (this.search2) {
      this.searchResult = [];
      this.user["关联设备ID"].forEach((ele: any) => {
        const index = ele["医院名称"].indexOf(this.search2);
        if (index !== -1) {
          this.searchResult.push(ele);
        }
      });
    } else {
      this.searchResult = this.user["关联设备ID"];
    }
  }
  private selectAll() {
    if (!this.user["关联设备ID"]) {
      this.user["关联设备ID"] = [];
    }
    this.sizhens.forEach((ele: any) => {
      const index = this.user["关联设备ID"].findIndex((val: any) => {
        return ele.hospital_id === val.hospital_id;
      });
      if (index === -1) {
        this.user["关联设备ID"].push(ele);
      }
    });
  }
  private delAll() {
    // 如果是搜索时则删除搜索的全部，否则删除所有选中
    if (this.hasSearch) {
      this.searchResult.forEach((ele: any) => {
        const index = this.user["关联设备ID"].findIndex((val: any) => {
          return ele.hospital_id === val.hospital_id;
        });
        if (index !== -1) {
          this.user["关联设备ID"].splice(index, 1);
        }
      });
    } else {
      this.user["关联设备ID"] = [];
    }
    this.searchResult = [];
  }
  // 返回全部
  private goAll() {
    this.hasSearch = false;
    this.search2 = "";
  }
  private hasSelect(item: any) {
    const index = this.user["关联设备ID"].findIndex((val: any) => {
      return item.hospital_id === val.hospital_id;
    });
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }
  private selectOneLeft(item: any) {
    if (!this.user["关联设备ID"]) {
      this.user["关联设备ID"] = [];
    }
    // 如果没有添加过则添加一个
    const index = this.user["关联设备ID"].findIndex((val: any) => {
      return item.hospital_id === val.hospital_id;
    });
    if (index === -1) {
      this.user["关联设备ID"].push(item);
    }
    if (this.hasSearch) {
      this.searchRight();
    }
  }
  private mounted() {
    const data = this.$store.state.zhanghu;
    if (data && data.user_id) {
      this.data = JSON.parse(JSON.stringify(data));
      this.type = "编辑";
      this.changeData();
    }
    this.getZuzhijiagouList(" ");
    this.getCenterList();
  }
}
