

































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import CommonCompose from "../components/common-compose.vue";
import SizhenCompose from "../components/sizhen-compose.vue";
import { UserDetail, RoleInInstitution } from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
    CommonCompose,
    SizhenCompose,
  },
})
export default class Name extends Vue {
  private type: any = "新增";
  private data: any = {};
  private get configuration() {
    return this.$store.state.configuration;
  }
  /**
   * @description 获得机构和角色的关系
   */
  private getRoleInInstitution(id: any, key: any, i: any) {
    return new Promise((resolve, reject) => {
      if (!id) {
        resolve(false);
        return;
      }
      const params: any = {
        params: {
          institution_id: id,
        },
      };
      RoleInInstitution(this, params)
        .then((data: any) => {
          this.$set((this as any)[key]["roles"], i, data);
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 获取用户详情
   */
  private getUserDetail() {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          user_id: this.data.user_id,
        },
      };
      UserDetail(this, params)
        .then((data: any) => {
          this.data = data;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    const data = this.$store.state.zhanghu;
    if (data && data.user_id) {
      this.type = "编辑";
    } else {
      this.type = "新增";
    }
  }
}
